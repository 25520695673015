
    import { defineComponent, onMounted, ref } from 'vue';
    import useEmitter from '@/composables/Emmiter'
    import AuthService from "@/services/AuthService";
    import ApiService from '@/services/ApiService';
    import { useStore } from 'vuex';
    import { Actions } from "@/store/enums/StoreEnums";
    import FilterBase from '@/layout/header/partials/filters/FilterBase.vue';
    import { useRoute } from 'vue-router';


    export default defineComponent({
        name: "filtro-agenda",
        components: {
            FilterBase,
        },

        setup(props, { emit }) {
            const store = useStore();
            const route = useRoute();

            let valorInicialCodConcessionaria;
            if(window.localStorage.getItem("id_usuario")){
                valorInicialCodConcessionaria = window.localStorage.getItem("filtroConcessionaria") ?? "";
            } else {
                valorInicialCodConcessionaria = window.sessionStorage.getItem("filtroConcessionaria") ?? "";
            }
            const codConcessionariaSelected:any = ref(valorInicialCodConcessionaria ?? "");
  
            const userInfo = AuthService.getUsuarioAuth();
            const token = userInfo.token;
            const emitter = useEmitter();

            const activeModal = ref(false);
            const closeModal = ref(false);
            const disabledClick = ref(false);

            onMounted(() => {
                if(store.getters.concessionariasList.length > 0 && store.getters.concessionariaSelectedInfo.codConcessionaria){
                    trataConcessionarias();
                }
            })

            store.watch(() => store.getters.concessionariasList, () => {
                trataConcessionarias();
            }, { deep: true });

            async function trataConcessionarias() {
                const concessionarias = store.getters.concessionariasList;

                optionsConcessionaria.value = concessionarias.map(concessionaria => {
                    return {
                        text: concessionaria.nomeFantasia,
                        value: concessionaria.codConcessionaria,
                        // disabled: concessionaria.modeloAgenda != "HyundaiV1",
                    }
                })
                // .sort((concessionariaA, concessionariaB) => {
                //     if (!concessionariaA.disabled && concessionariaB.disabled) {
                //     return -1;
                //     }
                //     if (!concessionariaB.disabled && concessionariaA.disabled) {
                //     return 1;
                //     }

                //     if (concessionariaA.disabled && !concessionariaA.disabled) {
                //     if (concessionariaA.text < concessionariaB.text){
                //         return -1;
                //     }
                //     if (concessionariaA.text > concessionariaB.text){
                //         return 1;
                //     }
                //     return 0;
                //     }

                //     return 0;
                // })
                codConcessionariaSelected.value = store.getters.concessionariaSelectedInfo.codConcessionaria;

                // Inicia filtro e emite dados iniciais
                if(token){
                    if(optionsConcessionaria.value.length > 1){
                        if(codConcessionariaSelected.value){
                            emiteMudanca();
                        } else {
                            activeModal.value = !activeModal.value;
                            showClose.value = false;
                        }
                    } else {
                        emiteMudanca();
                    }
                };
            };

            const optionsConcessionaria:any= ref([]);
        	const loading = ref(false);


            const modalFiltro:any = ref();
            const showClose = ref(false);

            async function emiteMudanca(){
                ///// valida o token no inicio da ação evitando que se o token esta expirado fazer varias autenticacoes iniciais
                await ApiService.valideTokenTime();
                /////

                if(window.localStorage.getItem("id_usuario")){
                    window.localStorage.setItem("filtroConcessionaria", codConcessionariaSelected.value);
                } else {
                    window.sessionStorage.setItem("filtroConcessionaria", codConcessionariaSelected.value);
                }
                
                store.dispatch(Actions.SET_CONCESSIONARIA_SELECTED, codConcessionariaSelected.value);
                store.dispatch(Actions.SET_MECANICO_LIST);
                store.dispatch(Actions.SET_MODELO_AGENDA);
                store.dispatch(Actions.SET_CONSULTORES_LIST);

                emitter.emit("update-calendar");
            };

            return {
                activeModal,
                closeModal,
                showClose,
                modalFiltro,
                loading, 
                emitter, 
                emiteMudanca, 
                optionsConcessionaria, 
                codConcessionariaSelected,
                disabledClick,
                route
            };
        }
    });
